import { preloadImages } from './utils';
import Lenis from '@studio-freight/lenis'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

// CARDS BENEFITS

let mm = gsap.matchMedia();
mm.add("(min-width: 800px)", () => {

const right = document.querySelector(".right");

const st = ScrollTrigger.create({
    trigger: ".wrapper",
    start: "top top",
    end: () => "+=" + (right.scrollHeight - window.innerHeight),
    pin: ".left",
    pinSpacing: false,
    //markers: true
});

const boxes = gsap.utils.toArray(".box");

boxes.forEach((box, i) => {
    gsap.to(box, {
        startAt: { scale: 1.1, rotation: -2 },
        scale: 1,
        rotation: 0,
        /* background: bgColors(i), */
        ease: "none",
        scrollTrigger: {
            trigger: box,
            start: "top center",
            end: "bottom center",
            scrub: true,

            //markers: { indent: 150 * i + 1 },
            //id: "bx-" + (i + 1)
        }
    });
});

});

/////////////////////////


const tween = gsap.to(".madeinaustria", {
    rotation: 1440,
    duration: 360,
    ease: "none",
    repeat: -1
}).totalProgress(0.5);

let tl = gsap.timeline();

ScrollTrigger.create({
    trigger: "#label",
    start: 'center center',
    end: '+=5000',
    onUpdate({ getVelocity }) {
        const velocity = getVelocity();

        let timeScale = 1;
        let endTimeScale = 1;

        if (velocity > 1) {
            timeScale = 10;
        } else {
            timeScale = -10;
            endTimeScale = -1;
        }

        tl.clear()
            .to(tween, {
                duration: 2,
                timeScale: timeScale
            })
            .to(tween, {
                duration: 2,
                timeScale: endTimeScale
            }, "+=1");
    }
})

///////////

/* gsap.to('.proto', {
    startAt: { xPercent: -400, rotation: 35},
    xPercent: 0,
    rotation: -5,
    ease: "none",
    scrollTrigger: {
        trigger: ".proto",
        start: 0,
        end: "top top",
        scrub: true
    }
}); */





///////////

const DOM = {
    sections: {
        columns: document.querySelector('.section--columns'),
        showcase: document.querySelector('.section--showcase'),
    },
    columns: document.querySelectorAll('.section--columns > .columns'),
    columnWraps: document.querySelectorAll('.section--columns .column-wrap'),
    itemsWrappers: document.querySelectorAll('.section--columns .column-wrap .column'),
    items: document.querySelectorAll('.section--columns .column__item'),
    images: document.querySelectorAll('.section--columns .column__item-img'),
};

// Lenis smooth scrolling
let lenis;

// Initialize Lenis smooth scrolling
const initSmoothScrolling = () => {
    lenis = new Lenis({
        lerp: 0.2,
        smooth: true,
    });
    const scrollFn = (time) => {
        lenis.raf(time);
        requestAnimationFrame(scrollFn);
    };
    requestAnimationFrame(scrollFn);
};

// GSAP Scroll Triggers

const scroll = () => {
    gsap.timeline({
        scrollTrigger: {
            start: 0,
            end: 'max',
            scrub: true
        }
    })
        .addLabel('start', 0)
        .to(DOM.sections.columns, {
            ease: 'none',
            startAt: { scale: 1.1, rotation: -10 },
            scale: 1,
            rotation: 45,
        }, 'start')
        .to(DOM.sections.columns, {
            scrollTrigger: {
                trigger: DOM.sections.showcase,
                start: 0,
                end: 'top top',
                scrub: true
            },
            ease: 'power4.inOut',
            startAt: {
                opacity: 1,
            },
            opacity: 1,
            // repeat once (go back to "startAt" values)
            yoyo: true,
            repeat: 1
        }, 'start')
        .to(DOM.columnWraps, {
            ease: 'none',
            yPercent: pos => pos % 2 ? 50 : -50
        }, 'start')
};

// Preload images
preloadImages('.column__item-img').then(() => {
    document.body.classList.remove('loading');
    // Lenis (smooth scrolling)
    initSmoothScrolling();
    // GSAP Scroll Triggers
    scroll();
});

/////////////////////

let masks = document.querySelectorAll('.mask');

masks.forEach( mask => {
    let image = mask.querySelector('img');

    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: mask,
            toggleActions: "restart none none reset"
        }
    });

    tl.set(mask, {autoAlpha: 1});

    tl.from(mask, 1.5, {
        xPercent: -100,
        ease: "Power2.out"
    });
    tl.from(image, 1.5, {
        xPercent: 100,
        scale: 2,
        delay: -1.5,
        ease: "Power2.out"
    });
})